/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  $.preloadImages = function() {
    // console.log(arguments[0]);
    for (var i = 0; i < arguments[0].length; i++) {
      $("<img />").attr("src", arguments[0][i]);
    }
  };

  // Get the current window width
  function getWindowWidth(){
    var bodyOverflowX = $('body').css('overflow-x');
    var bodyOverflowY = $('body').css('overflow-y');
    $('body').css('overflow', 'hidden');
    var windowWidth = $(window).width();
    $('body').css({
      'overflowX': bodyOverflowX,
      'overflowY': bodyOverflowY
    });

    return windowWidth;
  }

  // Set windowWidth variable
  var windowWidth = getWindowWidth();

  // Match heights
  function matchHeights() {
    if(windowWidth >= 767) {
      // Define elements to match
      $('#spotlightBanner .spotlightMatch').matchHeight({ byRow: true });

      $('.blogs .blogHeight').matchHeight({ byRow: true });
      $('.blogs .blog .titleHeight').matchHeight({ byRow: true });
      $('.blogs .blog .contentHeight').matchHeight({ byRow: true });

      $('.courses .course .inr ').matchHeight({ byRow: true });
      $('.courses .course .inr .title').matchHeight({ byRow: true });

      $('.products .product .inr ').matchHeight({ byRow: true });
      $('.products .product .inr .title').matchHeight({ byRow: true });

      $('#testimonials .testimonial .content ').matchHeight({ byRow: true });
      $('#wpcf7-f4-o1 .wpcf7-form .buttonMatch').matchHeight({ byRow: true });
      $('#mc_embed_signup #mc-embedded-subscribe-form .newsletterHeight').matchHeight({ byRow: true });
      $('.directionsHolder #directions .directionsHeight').matchHeight({ byRow: true });

      // Change elements opacity to 1 after matching, opacity set to 0 in css
      $('.courses').css('opacity', '1');
      $('.products').css('opacity', '1');
      $('#spotlightBanner').css('opacity', '1');
      $('.blogs').css('opacity', '1');
    } else {
      $('.courses').css('opacity', '1');
      $('.products').css('opacity', '1');
      $('#spotlightBanner').css('opacity', '1');
      $('.blogs').css('opacity', '1');
    }
  }

  // Functions to fire on document ready and window resize
  function onReadyResponsive(initial, windowWidth){

  }

  // Functions to fire on window load and window resize
  function onLoadResponsive(initial, windowWidth){
    matchHeights();
  }


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Woocommerce Checkout page - custom 'Additional Info' field for website referrals
        $("#shipping_referral_specify_field").hide();

        const referral_select = $('.referral_select');
        if(referral_select.length > 0) {
          referral_select.select2({});

          referral_select.on("select2:select", function(e) {
            var specifyFields = [
              "Magazine Advertisement (please specify which magazine)",
              "Competition flyer (please specify)",
              "Other (please specify)"
            ];

            $("#shipping_referral_specify_field").toggle(specifyFields.indexOf(e.params.data.id) != -1);
          });
        }
        //

        // Preload home banner images to increase page load time
        var banner_images = [];
        $('#heroBanner .slides li').each(function() {
          banner_images.push($(this).css('background-image').replace(/^url\(['"]?/,'').replace(/['"]?\)$/,''));
        });

        if(banner_images.length) {
          $.preloadImages(banner_images);
        }

        // Build sub menu navigation
        $('ul#menu-primary-navigation li.dropdown, ul#menu-primary-navigation-welsh li.dropdown').mouseenter(function() {
          if ($('#subMenuHolder').length !== '') {
            $('#subMenuHolder').stop().slideToggle('slow');
            $('#subMenuHolder').remove();
          }

          $(this).addClass('subMenuActive');
          $(this).prev().addClass('noAfter');

          $('.banner').after('<div id="subMenuHolder"><div class="container"></div></div>');
          var submenu = $(this).children('.sub-menu');
          submenu.clone().appendTo('#subMenuHolder .container');
          $('#subMenuHolder').stop().slideToggle('slow');
        });

        $('ul#menu-primary-navigation li.dropdown, ul#menu-primary-navigation-welsh li.dropdown').mouseleave(function() {
          if ($('#subMenuHolder:hover').length === 0) {
            $('#subMenuHolder').stop().slideToggle('slow');
            $('ul#menu-primary-navigation li.dropdown, ul#menu-primary-navigation-welsh li.dropdown').removeClass('subMenuActive');
            $('ul#menu-primary-navigation li.dropdown, ul#menu-primary-navigation-welsh li.dropdown').removeClass('noAfter');
          }
          $('#subMenuHolder').mouseleave(function() {
            $('#subMenuHolder').stop().slideToggle('slow');
            $('ul#menu-primary-navigation li.dropdown, ul#menu-primary-navigation-welsh li.dropdown').removeClass('subMenuActive');
            $('ul#menu-primary-navigation li.dropdown, ul#menu-primary-navigation-welsh li.dropdown').removeClass('noAfter');
          });
        });


        // Insert span after li elements for chevron
        $('#menu-footer-menu li').after('<span>/</span>');

        // Toggle sidebar sub menu
        $('#subMenuToggle').click(function(){
          $('#subMenuToggle').toggleClass('open');
          $('#sidebarMenu ul.menu >ul').slideToggle();
        });

        // Expand sub-menu if active page is within sub-menu
        if ( $('#sidebarMenu .sub-menu li').hasClass('active') ) {
          $('#subMenuToggle').addClass('open');
          $('#sidebarMenu .sub-menu').css('display', 'block');
        } else {
          $('#subMenuToggle').removeClass('open');
        }

        // Add scroll effect to newsletter anchor
        $('#newsletterScroll .inr a').click(function(){
          var target = $('#newsletter').offset().top;

          $('html, body').animate({scrollTop : target },1000);
          return false;
        });
        

        $('form#mc-embedded-subscribe-form').submit(function(){
          $.ajax({
            success: function(responseStr) {

              $('#newsletter-success').modal();
                
            }
          });
        });



        
        // Move label on input focus
        $('form .customInput').focusin(function() {
          var thisForm = $(this).attr('data-id');
          $('.inputHolder.'+thisForm).addClass('inputActive');
        });

        // On leaving input, check if input has value
        $('form .customInput').blur(function() {
          // If input is empty, move label back to original position
          if( $(this).val().length === 0 ) {
            var thisForm = $(this).attr('data-id');
            $('.inputHolder.'+thisForm).removeClass('inputActive');
          }
        });


        // Check if course filter fields are empty and exclude them from search if true
        $('#courseFilter').submit(function() {
          $('select option[value=""]:selected').closest("select").attr('name', '');
        });


      },

      finalize: function() {
      // JavaScript to be fired on all pages, after page specific JS is fired

        // Toggle mobile menu
        $('#menuIcon').click(function(){
          $('#menuIcon, .mobileMenuHolder').toggleClass('open');
          $('#mobileMenu').stop().slideToggle();
        }); 

        // Toggle mobile menu children
        $('#mobileMenu .toggle').click(function(){
          $(this).toggleClass('open').parent().children('ul').stop().slideToggle();
        });

        // Expand mobile menu if active page is within sub-menu
        if ( $('#mobileMenu .navbar-nav-mobile li').hasClass('active') ) {
          // $('#subMenuToggle').addClass('open');
          $('#mobileMenu .navbar-nav-mobile li.active ul').css('display', 'block');
        } else {
          // $('#subMenuToggle').removeClass('open');
        }

        // Toggle faq answers
        $('.question-toggle').click(function(){
          var questionID = $(this).attr('data-id');
          var questionTopic = $(this).attr('data-type');

          $(this).parent().parent().toggleClass('open');
          $(this).toggleClass('open');
          $('#answer-'+questionTopic+'-'+questionID).stop().slideToggle();
        });

        // Set question toggle height to same height as question to ensure central alignment
        if(windowWidth >= 480) {
          var questionHeight = $('.question').height();

          // console.log(questionHeight);
          // $('.question-toggle').height(questionHeight);
          $('.question-toggle').css({
            'height': questionHeight,
            // 'margin-top': questionHeight
          });
        }


        // Initialize Single standard banner
        if($('#standardBanner').length){
          var standardBanner = $('#standardBanner');
          standardBanner.flexslider({
            animation: 'fade',
            controlNav: false,
            directionNav: false,
            slideshowSpeed: 5000,
            animationSpeed: 800
          });
        }


        // Initialize Non-home page hero banners        
        if($('#heroBanner').length){
          var heroBanner = $('#heroBanner');
          heroBanner.flexslider({
            animation: 'fade',
            controlNav: true,
            directionNav: false,
            slideshowSpeed: 5000,
            animationSpeed: 800
          });

          // Initialize custom banner navigation
          $('.heroBannerPrev').on('click', function(){
            $('#heroBanner').flexslider('prev');
            return false;
          });

          $('.heroBannerNext').on('click', function(){
            $('#heroBanner').flexslider('next');
            return false;
          });

          if(windowWidth >= 480) {
            $('.flex-control-nav').clone().appendTo('.inner .holder .bannerDots');
          } else if(windowWidth < 480) {
            $('.flex-control-nav').clone().appendTo('.holder .bannerDots');
          }
        }


        // Initialize Spotlight banner
        if($('#spotlightBanner').length){
          var spotlightBanner = $('#spotlightBanner');
          spotlightBanner.flexslider({
            animation: 'fade',
            // pauseOnHover: true,
            controlNav: true,
            directionNav: false,
            // controlsContainer: $('.custom-controls-container'),
            // customDirectionNav: $('#homeBannerNav div'),
            slideshowSpeed: 8500,
            animationSpeed: 800
          });
        }


        // Initialize Testimonials slider
        if($('#testimonials').length){
          var testimonialsSlider = $('#testimonials');
          testimonialsSlider.owlCarousel({
            loop:true,
            margin:10,
            dots:true,
            responsive:{
              0:{
                  items:1
              },
              767:{
                  items:2
              }
            }
          });

          // Go to the next item
          $('.testimonialNext').click(function() {
            testimonialsSlider.trigger('next.owl.carousel');
          });
          // Go to the previous item
          $('.testimonialPrev').click(function() {
            testimonialsSlider.trigger('prev.owl.carousel');
          });
        }


        // WooCommerce
        var optionText = $('#wc_bookings_field_resource').html();
        if (optionText) {
          var newOptionText = optionText.replace(/\(.*\)/g,'');
          $('#wc_bookings_field_resource').html(newOptionText);
        }

        // Add blank option to room select
        $('#wc_bookings_field_resource').prepend("<option value='' selected='selected'>Select room type</option>");

        // Move quantity select below room select and add attendees label
        $('.quantity_select.bookings').insertAfter('.wc_booking_field_room.wc_bookings_field_resource');
        $('.quantity_select.bookings').before( $('<label>Attendees</label>') );

        // Change resource/room label
        $('.single-product .product .booking .inr #wc-bookings-booking-form .wc_bookings_field_resource > label').text(function(index, text) {
          return text.replace('Type', 'Room');
        });

        // Add classes to deposit option switcher
        $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label[for="pay-deposit"]').addClass('selected');
        $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label[for="pay-full-amount"]').addClass('unselected');

        $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label').click(function() {
          // If clicked element is unselected
          if ( $(this).hasClass('unselected') ) {
            $(this).removeClass('unselected'); // Remove unselected class
            $(this).addClass('selected'); // Add selected class
            $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label').not(this).removeClass('selected'); // Remove selected class from other elements
            $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label').not(this).addClass('unselected'); // Add unselected class to other elements
          } else if ( $(this).hasClass('selected') ) {
            $(this).removeClass('selected'); // Remove selected class
            $(this).addClass('unselected'); // Add unselected class
            $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label').not(this).removeClass('unselected'); // Remove unselected class from other elements
            $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label').not(this).addClass('selected'); // Add selected class to other elements
          }
          // $(this).toggleClass('selected');
          // $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label').not(this).toggleClass('unselected');
        });

        $('form.checkout #customer_details .woocommerce-billing-fields .create-account > p').html(function(index, text) { return text.replace('at the top of the page', 'via the link in the footer'); });

        // Basket 'Persons' text
        $('.woocommerce .shop_table tr.cart_item td.product-name dl.variation dt.variation-Persons').html('Attendees:');
        // Basket 'Booking Type' text
        $('.woocommerce .shop_table tr.cart_item td.product-name dl.variation dt.variation-BookingType').html('Room:');

        // Auto-select date for booking and pre-fill date fields
        var selectedDate = $('#availableDates').val();

        if (selectedDate) {
          var selectedDateBreakdown = selectedDate.split('-');

          $( "input[name*='wc_bookings_field_start_date_day']" ).val( selectedDateBreakdown[0] );
          $( "input[name*='wc_bookings_field_start_date_month']" ).val( selectedDateBreakdown[1] );
          $( "input[name*='wc_bookings_field_start_date_year']" ).val( selectedDateBreakdown[2] );

          $('.wc-bookings-booking-form-button').removeAttr("disabled");
        }


        // Welsh site
        if ($('body').hasClass('cy')) {

          $('#payment_method_worldpay').click(function() {
            $('.payment_box.payment_method_worldpay').css({ display: 'block' });
            $('.payment_box.payment_method_cheque').css({ display: 'none' });
          });

          $('#payment_method_cheque').click(function() {
            $('.payment_box.payment_method_worldpay').css({ display: 'none' });
            $('.payment_box.payment_method_cheque').css({ display: 'block' });
          });

        // Welsh translations
        // Booking form (single product template)
          // Booking form attendees label
          $('.single-product .product .booking .inr #wc-bookings-booking-form > label').text(function(index, text) {
            return text.replace('Attendees', 'Mynychwyr');
          });
          // Booking form attendees placeholder
          $('.single-product .product .booking .inr #wc-bookings-booking-form .quantity_select #wc_bookings_field_persons > option:first-child').text('Dewis nifer o fynychwyr');
          // Booking form resource/room label
          $('.single-product .product .booking .inr #wc-bookings-booking-form .wc_bookings_field_resource > label').text(function(index, text) {
            return text.replace('Room', 'Ystafell');
          });
          // Booking form resource/room placeholder
          $('.single-product .product .booking .inr #wc-bookings-booking-form .wc_bookings_field_resource #wc_bookings_field_resource > option:first-child').text('Dewis sut ystafell');
          // Booking form book button
          $('.single-product .product .booking .inr #wc-bookings-booking-form .book .wc-bookings-booking-form-button').html('Archebu Nawr');

          // Basket 'continue shopping button'
          $('.woocommerce .woocommerce-message a.button').html('Parhau Siopa');
          // Basket 'Course' text
          $('.woocommerce .shop_table.cart th.product-name').html('Cwrs');
          // Basket 'Course' text
          $('.woocommerce .shop_table.cart th.product-price').html('Ffi’r Cwrs');
          // Basket 'Deposit' Text
          $('.woocommerce .shop_table.cart tr td.product-subtotal').html(function(index, text) {
            return text.replace('Deposit', 'Blaendal ');
          });
          // Basket 'Remaining' Text
          $('.woocommerce .shop_table.cart tr td.product-subtotal').html(function(index, text) { return text.replace('Remaining', 'Sy\'n weddill'); });
          // Basket added to cart text
          $('.woocommerce .woocommerce-message').html(function(index, text) {
            return text.replace('has been added to your cart.', 'wedi cael ei ychwanegu at eich trol siopa.');
          });
          // Basket Totals 'To Pay' text
          $('.woocommerce .cart-collaterals .cart_totals table tr.order-paid th').html('I’w dalu');
          // Basket Totals 'Remaining Amount' text
          $('.woocommerce .cart-collaterals .cart_totals table tr.order-remaining th').html('Swm yn weddill');
          // Basket Totals 'Coupon' text
          $('.woocommerce .cart-collaterals .cart_totals table tr.cart-discount th').html(function(index, text) { return text.replace('Coupon:', 'Cwpon:'); });
          // Basket Totals 'Remove' text
          $('.woocommerce .cart-collaterals .cart_totals table tr.cart-discount td a.woocommerce-remove-coupon').html(function(index, text) { return text.replace('Remove', 'Tynnwch'); });

          // Checkout Order Review 'Booking Date' text
          $('.woocommerce  .shop_table tr.cart_item td.product-name dl.variation dt.variation-BookingDate').html('Archebu Dyddiad:');
          // Checkout Order Review 'Booking Type' text
          $('.woocommerce .shop_table tr.cart_item td.product-name dl.variation dt.variation-BookingType').html('Ystafell:');
          // Checkout Order Review 'Course' text
          $('.woocommerce  .shop_table tr th.product-name').html('Cwrs');
          // Checkout Order Review 'Booking Date' text
          $('.woocommerce  .shop_table tr.cart_item td.product-name dl.variation dt.variation-BookingDate').html('Archebu Dyddiad:');
          // Checkout Order Review 'Booking Type' text
          $('.woocommerce .shop_table tr.cart_item td.product-name dl.variation dt.variation-BookingType').html('Ystafell:');
          // Checkout Order Review 'To Pay' text
          $('.woocommerce table tr.order-paid th').html('I’w Dalu');
          // Checkout Order Review 'Remaining Amount' text
          $('.woocommerce table tr.order-remaining th').html('Swm yn Weddill');
          // Checkout Order Review 'Booking Date' text
          $('.woocommerce .shop_table tr.cart_item td.product-name dl.variation dt.variation-Persons').html('Mynychwyr:');
          // Checkout Order Review 'Deposit' Text
          $('.woocommerce .shop_table tr.cart_item .product-total').html(function(index, text) { return text.replace('Deposit', 'Blaendal '); });
          // Checkout Order Review 'Remaining' Text
          $('.woocommerce .shop_table tr.cart_item .product-total').html(function(index, text) { return text.replace('Remaining', 'Sy\'n weddill'); });
          // Checkout Order Review 'Cheque Payment' text
          $('.woocommerce .woocommerce-checkout #order_review #payment .payment_methods li.payment_method_cheque > label').html('Talu â Siec');
          // Checkout Order Review 'Please send your cheque to' text
          $('.woocommerce .woocommerce-checkout #order_review #payment .payment_methods li.payment_method_cheque .payment_box > p').html(function(index, text) {
            return text.replace('Please send your cheque to', 'Gyrrwch eich siec i');
          });
          // Checkout Order Review 'Pay with' text
          $('.woocommerce .woocommerce-checkout #order_review #payment .payment_methods li.payment_method_worldpay > label').html(function(index, text) {
            return text.replace('Pay with', 'Talu gyda');
          });
          // Checkout Order Review 'Cheque Payment' text
          $('.woocommerce .woocommerce-checkout #order_review #payment .payment_methods li.payment_method_worldpay > label').html(function(index, text) {
            return text.replace('Cheque Payment', 'Talu Siec');
          });
           $('#payment .payment_methods .payment_method_worldpay .payment_box.payment_method_worldpay p').html(function(index, text) {
            return text.replace('Credit Card via WorldPay', 'Cerdyn Credyd trwy WorldPay');
          });
          $('#payment .payment_methods .payment_method_cheque .payment_box.payment_method_cheque p').html(function(index, text) {
            return text.replace('Please send a cheque made payable to', 'Anfonwch siec yn daladwy i');
          });
          $('#payment .payment_methods .payment_method_cheque .payment_box.payment_method_cheque p').html(function(index, text) {
            return text.replace('to', 'i');
          });

          $('form.checkout #customer_details .woocommerce-billing-fields .create-account p:first-child').html(
            'Gallwch greu cyfrif drwy roi’ch manylion isod. Os ydych yn gwsmer sy\'n dychwelyd , os gwelwch yn dda mewngofnodwch trwy\'r ddolen yn y troedyn'
          );
          $('form.checkout #customer_details .woocommerce-billing-fields .create-account #account_password_field label[for="account_password"]').html(function(index, text) {
            return text.replace('Account password', 'Cyfrinair Cyfrif');
          });

          // Cart booking removed text
          $('.woocommerce .woocommerce-info').html(function(index, text) { return text.replace('Coupon code applied successfully', 'Cod cwpon gais llwyddiannus'); });
          $('.woocommerce .woocommerce-info').html(function(index, text) { return text.replace('A booking for', 'Mae archeb am'); });
          $('.woocommerce .woocommerce-info').html(function(index, text) {
            return text.replace('has been removed from your cart due to inactivity', 'wedi cael ei dynnu o’ch basged gan eich bod wedi bod yn segur am gyfnod hir');
          });
        }

        // Fire after ajax functions are complete
        $(document).ajaxComplete(function() {
          // Remove 'Booking Cost:' from cost calculator
          var bookingCostContainer = $('#bookingCost');
          var bookingCost = $('#bookingCost .woocommerce-Price-amount');
          if (bookingCostContainer.length > 0) {
            if (bookingCost.length > 0) {
              bookingCostContainer.html(bookingCost.html());
            }
          }

          // Check if cost calculator div contains error/validation message or cost
          if (bookingCost.length > 0) { // If cost
            bookingCostContainer.addClass('large');
          } else { // If message
            bookingCostContainer.removeClass('large');
          }

          // Booking form translations after ajax
          if (bookingCost.length <= 0) { // If is a booking message

            if($('body').hasClass('cy')) { // If welsh

              // Change 'booking validation messages
              bookingCostContainer.text(function(index, text) {
                return text.replace('Please choose a resource type', 'Dewis Ystafell');
              });

              bookingCostContainer.text(function(index, text) {
                return text.replace('Persons are required - please enter the number of persons above', 'Dewiswch nifer y mynychwyr');
              });

              bookingCostContainer.html(function(index, text) { return text.replace('There are', 'Mae'); });

              bookingCostContainer.html(function(index, text) { return text.replace('places remaining on', 'o leoedd ar ôl ar'); });
            } else {

              bookingCostContainer.text(function(index, text) {
                return text.replace('Please choose a resource type', 'Choose a room');
              });
            
              bookingCostContainer.text(function(index, text) {
                return text.replace('Persons are required - please enter the number of persons above', 'Choose number of attendees');
              });

              // Checkout 'Persons' text
              $('.woocommerce .shop_table tr.cart_item td.product-name dl.variation dt.variation-Persons').html('Attendees:');

              // Checkout 'Booking Type' text
              $('.woocommerce .shop_table tr.cart_item td.product-name dl.variation dt.variation-BookingType').html('Room:');

            }

          }

          // Welsh translations after ajax
          if($('body').hasClass('cy')) {
            // Deposit option text
            $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form label.deposit-option').html(function(index, text) {
              return text.replace('Deposit Option:', 'Opsiwn Blaendal:');
            });

            $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form span#deposit-suffix').html(function(index, text) {
              return text.replace('per person', 'y pen');
            });

            // Deposit option buttons
            $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label[for="pay-deposit"]').text('Talu blaendal');
            
            $('.single-product .product .right .booking #wc-bookings-booking-form #wc-deposits-options-form .deposit-options label[for="pay-full-amount"]').text('Swm Llawn');
            
            $('.single-product .product .right .booking #wc-bookings-booking-form').css({ opacity: 1 });
          }

        });

        $(window).load(function(){
          onLoadResponsive(true, getWindowWidth());
        });

        $(window).resize(function() {
          var windowWidth = getWindowWidth();
          onLoadResponsive(false, windowWidth);
          onReadyResponsive(false, windowWidth);
        });

      }
    },


    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Preload home banner images to increase page load time
        var home_banner_images = [];
        $('#homeBanner .slides li').each(function() {
          home_banner_images.push($(this).css('background-image').replace(/^url\(['"]?/,'').replace(/['"]?\)$/,''));
        });

        if(home_banner_images.length) {
          $.preloadImages(home_banner_images);
        }

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // Home banner
        if($('#homeBanner').length){
          var homeBanner = $('#homeBanner');
          homeBanner.flexslider({
            animation: 'fade',
            controlNav: true,
            directionNav: false,
            slideshowSpeed: 5000,
            animationSpeed: 800,
            // controlsContainer: '.bannerDots',
            manualControls: '.bannerDots ol li a',
          });

          //Initialize custom banner navigation
          $('.homeBannerPrev').on('click', function(){
            $('#homeBanner').flexslider('prev');
            return false;
          });

          $('.homeBannerNext').on('click', function(){
            $('#homeBanner').flexslider('next');
            return false;
          });

          // if(windowWidth >= 480) {
          //   $('.flex-control-nav').clone().appendTo('.inner .holder .bannerDots');
          // } else if(windowWidth < 480) {
          //   $('.flex-control-nav').clone().appendTo('.holder.visible-xs .bannerDots');
          // }
        }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
